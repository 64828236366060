<template>
  <div class="page-face success">
    <p class="success-top">
      <template v-if="text">
        <img v-if="text==='认证成功'" src="./img/success.png" alt=""/>
        <img v-else src="./img/error.png" alt=""/>
      </template>
      <span>{{ text }}</span>
    </p>
    <p class="btn" @click="goto()">返回</p>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue'
import {Toast} from 'vant'
import {GetUrlParams, isEmptyObj} from '../../utils/index'
import API from '@/server/api'
import {useRouter} from 'vue-router'

export default {
  setup() {
    const Router = useRouter()
    const state = reactive({
      form: {
        creditNo: '',
        bizId: ''
      },
      text: ''
    })
    const goto = () => {
      const PUBLIC_PATH = process.env.VUE_APP_API_PUBLIC_URL
      window.location.href = PUBLIC_PATH + '/index.html#/infoNavigation?key=1'
    }

    const init = async () => {

      try {
        const params = {...state.form}
        const res = await API.liveResult({...params})
        if (res.success) {
          if (res.data) {
            state.text = '认证成功'
          } else {
            state.text = '认证失败'
          }
        } else {
          state.text = '认证失败'
        }
      } catch (err) {
        console.log(err.message)
        Toast(err.message)
      }
    }

    onMounted(async () => {
      state.form.creditNo = localStorage.getItem("creditNo");
      const params = GetUrlParams()
      if (!isEmptyObj(params) && params.biz_id) {
        const index = params.biz_id.lastIndexOf('#')
        state.form.bizId = decodeURIComponent(params.biz_id.substring(0, index))
        await init()
      }
    })


    return {
      ...toRefs(state),
      goto
    }
  }
}
</script>

<style lang="less">
.success {
  box-sizing: border-box;
  padding-top: 70px;
}

p {
  margin: 0;
  padding: 0;
}

.title {
  height: 100px;
  text-align: center;
  background-color: #fff;
  width: 100%;
  line-height: 100px;
  font-size: 36px;

  font-weight: 500;
  color: #1e1e1e;
}

.success {
  width: 100%;
  height: 100vh;
  background: #f7f6f9;
  display: flex;
  flex-direction: column;
  align-items: center;

  .success-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 122px;

    img {
      width: 196px;
      height: 195px;
      margin-top: 190px;
      margin-bottom: 36px;
    }

    span {
      font-size: 32px;

      font-weight: 500;
      color: #7ed321;
    }
  }

  .cont {
    font-size: 32px;

    font-weight: 400;
    color: #757575;
    margin: 0 48px 0 43px;
    text-align: center;
    // line-height: 44px;
  }

  .cont2 {
    margin-top: 10px;
    margin-bottom: 106px;
  }

  .btn {
    width: 245px;
    height: 76px;
    border-radius: 38px;
    border: 1px solid #817ff7;
    font-size: 32px;

    font-weight: 400;
    color: #817ff7;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
